<template>
	<div class="page-container vh-100 overflow-auto">
		<div class="page-header-wrapper">
			<page-header title="Trip Tracking" pre-title="" class="mb-0 pb-0"> </page-header>
		</div>

		<div class="d-flex content-wrapper p-0">
			<div v-if="isLoading" class="d-flex justify-content-center align-items-center w-100">
				<div class="spinner-border" role="status"></div>
			</div>

			<template v-else>
				<div class="content-area">
					<template v-if="activeTrips && activeTrips.length">
						<div class="content-header">
							<div class="col-md-4">
								<div class="input-group input-group-flush d-flex flex-row border"
									v-if="activeTrips && activeTrips.length">
									<span class="input-group-text border-0">
										<i class="fe fe-search"></i>
									</span>
									<input v-model.trim="searchQuery" class="form-control h-100 list-search" type="search"
										placeholder="Search active trip" />
								</div>
							</div>
							<div class="col-auto d-flex align-items-center">
								<filter-button @item-selected="selectFilter('tripState', $event)" :options="stateFilters">
									<template #selector>
										<div class="filter-button">
											<img class="mr-2" src="@/assets/img/icons/navigation.svg" />
											<span v-if="filter.tripState">{{ filter.tripState.label }}</span>
											<span v-else>Status</span>
										</div>
									</template>

									<template #option="data">
										<span>
											{{ data.label }}
										</span>
									</template>
								</filter-button>

								<filter-button class="ml-3" @item-selected="selectFilter('city', $event)" :options="cityFilters">
									<template #label> City: {{ filter.city.name }} </template>
									<template #selector>
										<div class="filter-button">
											<img class="mr-2" src="@/assets/img/icons/marker.svg" />
											<span v-if="filter.city.city_id">{{ filter.city.name }}</span>
											<span v-else>City</span>
										</div>
									</template>

									<template #option="data">
										<span @click="selectFilter('city', data)">
											{{ data.name }}
										</span>
									</template>
								</filter-button>

								<div class="ml-3">
									<h5 class="header-title py-4">
										Showing
										{{
											filterActive ? `${searchResults.length}` : `${activeTrips.length}`
										}}
										of
										{{ `${filterActive ? activeTrips.length : totalTrips} active trips` }}
									</h5>
								</div>
							</div>
						</div>

						<div class="content-body">
							<div class="h-100 map-spread">
								<div id="google-map-area" class="h-100 map-area" style="width: 100%"></div>
							</div>

							<div class="col-12 col-md-4 px-0 h-100">
								<div class="bg-white rounded-0 h-100 overflow-auto mb-0">
									<div class="px-3">
										<div class="" v-if="activeTrips.length">
											<div class="list-group list-group-flush" v-if="searchResults.length">
												<div class="list-group-item list-group-item-action flex-column pointer"
													v-for="trip in searchResults" :key="trip.id" href="#" :ref="`activeTrip_${trip.id}`"
													:id="`activeTrip_${trip.id}`" @click.prevent="selectActiveTrip(trip)" :class="selectedTrip !== null && selectedTrip.id === trip.id
														? 'list-group-item--active'
														: ''
													">
													<div class="row flex-nowrap">
														<div class="flex-grow-1">
															<div class="d-flex">
																<div class="mr-3">
																	<img class="category-icon" v-if="getVehicleCategoryImage(trip)"
																		:src="getVehicleCategoryImage(trip)" />
																	<div v-else class="category-placeholder">
																		<img class="img" src="@/assets/img/icons/bus_grey.svg" />
																	</div>
																</div>

																<div class="d-flex px-0 route-info flex-grow-1">
																	<div class="px-0 mb-3 d-flex route mr-3 flex-grow-1">
																		<div class="flex-grow-1">
																			<div class="d-flex w-100 justify-content-between flex-grow-1">
																				<div>
																					<route-description :titleWeight="300" :max-width="250"
																						:pickup="trip.route.pickup" :destination="trip.route.destination">
																					</route-description>
																				</div>
																				<div class="d-flex flex-column">
																					<h3 class="mb-2 flex-grow-1 route-code">
																						{{ trip.route.route_code }}
																					</h3>
																					<div class="state" :class="trip.statusClass">
																						<inline-svg class="img" :src="trip.statusIcon"></inline-svg>
																						<span class="state-label ml-2">{{
																							trip.lastUpdateState
																						}}</span>
																					</div>
																				</div>
																			</div>
																			<!--
                                      <div>
                                        <route-description
                                          :max-width="350"
                                          :pickup="trip.route.pickup"
                                          :destination="trip.route.destination"
                                        >
                                        </route-description>
                                      </div> -->
																		</div>
																	</div>
																</div>
															</div>

															<div class="d-flex mt-3">
																<span class="badge badge-light mr-2">Started at {{ trip.start_trip | dateformat }}</span>

																<span title="Vehicle registration number" class="badge badge-light mr-2"
																	v-if="trip.driver.vehicle?.registration_number">
																	{{ trip.driver.vehicle?.registration_number || "" }}
																</span>
																<span title="Vehicle make and model" class="badge badge-light mr-2">{{
																		trip.driver.vehicle?.brand }}
																	{{ trip.driver.vehicle?.name }}
																	({{ trip.driver.vehicle?.seats }} seats)
																</span>

																<span title="Driver name" class="badge badge-light mr-2">{{ trip.driver.fname }} {{
																	trip.driver.lname }}</span>
															</div>
														</div>
													</div>
												</div>
												<div v-if="loadingMoreTrips"
													class="d-flex mt-5 mb-3 justify-content-center align-items-center w-100">
													<div class="spinner-border" role="status"></div>
												</div>
												<div v-else-if="canLoadMoreTrips"
													class="d-flex mt-5 mb-3 justify-content-center align-items-center w-100">
													<label class="pointer font-weight-bold" @click="loadMoreTrips">Show more trips</label>
												</div>
											</div>
											<div class="d-flex justify-content-center mt-8 px-4" v-else>
												<p class="text-center">
													Sorry, no active trips were found matching your search or filter
													criteria
													<em>{{ searchQuery }}</em>
												</p>
											</div>
										</div>
										<div class="d-flex justify-content-center mt-8 px-4" v-else>
											<p class="text-center">There are currently no active trips</p>
										</div>
									</div>
								</div>
							</div>
						</div>
					</template>
					<div class="d-flex justify-content-center align-items-center w-100 h-100" v-else>
						<div class="d-flex flex-column align-items-center">
							<img class="mx-auto empty-state-img" src="@/assets/img/bus_placeholder.svg" />
							<div class="text-center" v-if="filter.city.id">
								<p>
									There are currently no active trips for the selected city
									{{ filter.city.name }}
								</p>
								<button @click="clearFilters()" class="btn btn-sm btn-primary">
									Clear Filter
								</button>
							</div>
							<p class="text-center" v-else>There are currently no active trips</p>
						</div>
					</div>
				</div>
			</template>
		</div>
		<trip-details-modal :show="isPassengersModalShown" :selected-marker-trip-data="selectedMarkerTripData"
			@close="isPassengersModalShown = false"></trip-details-modal>
	</div>
</template>

<script>
import { Loader } from '@googlemaps/js-api-loader'
import moment from 'moment'
import TripDetailsModal from '@/components/modals/BusMarkerDetailsModal.vue'
import { busMarker } from '@/assets/svg/inline-svg'
import RouteResource from '@/api/route'
import PageHeader from '@/components/layout/PageHeader'
import InlineSvg from 'vue-inline-svg'
import { timeSince } from '@/utils/helpers'
import FilterButton from '@/components/core/FilterButton'
import RouteDescription from '@/components/modules/routes/RouteDescription'

const gMapLoader = new Loader({
  apiKey: process.env.VUE_APP_GOOGLE_APIKEY,
  version: 'weekly',
  libraries: ['geometry']
})

export default {
  components: {
    FilterButton,
    PageHeader,
    TripDetailsModal,
    InlineSvg,
    RouteDescription
  },
  filters: {
    dateformat(v) {
      return moment(v).format('MMM Do YYYY, h:mm a')
    }
  },
  data() {
    return {
      activeTrips: [],
      activeTrip: null,
      mapInstance: null,
      uniqueTripData: {},
      uniqueTripTracking: {},
      searchQuery: '',
      activeTripsMarkers: {},
      isHovered: false,
      isLoading: false,
      isPassengersModalShown: false,
      selectedMarkerTripData: null,
      tripsPanelOpen: false,
      totalTrips: 0,
      loadingMoreTrips: false,
      pageSize: 20,
      filter: {
        city: {
          id: null,
          name: 'All Cities',
          city_id: null
        },
        tripState: null
      },
      stateFilters: [
        {
          key: null,
          label: 'All'
        },
        {
          key: 'state--online',
          label: 'Online'
        },
        {
          key: 'state--offline',
          label: 'Offline'
        },
        {
          key: 'state--updated',
          label: 'Recently Updated'
        },
        {
          key: 'state--pending',
          label: 'Pending Update'
        }
      ],
      cityFilters: [],
      selectedTrip: null,
      currentPage: 1,
      changeDetectionRef: null
    }
  },
  watch: {
    searchResultLength(oldValue, newValue) {
      // update marker visibility when filter is applied or rem
      if (newValue !== oldValue) {
        this.updateMarkerVisibility()
      }
    },
    'filter.city'(oldValue, newValue) {
      if (newValue) {
        this.fetchActiveTrips(false)
      }
    }
  },
  computed: {
    filterActive() {
      return this.filter.tripState || this.searchQuery
    },
    searchResultLength() {
      return this.searchResults?.length
    },
    currentUser() {
      return this.$store.getters.currentUser
    },
    token() {
      if (!this.currentUser) return null
      return this.currentUser.token.token
    },
    searchResults() {
      if (this.searchQuery || this.filter.tripState?.key) {
        const searchResults = this.activeTrips.filter((trip) => {
          let applicable = true
          if (this.searchQuery) {
            applicable =
              applicable &&
              this.searchQuery
                .toLowerCase()
                .split(' ')
                .every((v) => {
                  return (
                    `${trip.route.pickup} ${trip.route.destination} ${trip.route.route_code}` +
                    `${trip.driver.fname} ${trip.driver.lname}`
                  )
                    .toLowerCase()
                    .includes(v)
                })
          }
          if (this.filter.tripState?.key) {
            applicable = applicable && trip.statusClass === this.filter.tripState?.key
          }
          return applicable
        })
        return searchResults
      } else {
        return this.activeTrips
      }
    },
    canLoadMoreTrips() {
      return this.totalTrips > 0 ? this.activeTrips.length < this.totalTrips : false
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.fetchActiveTrips()
    })
  },
  created() {
    this.getCities()
  },
  beforeUnmount() {
    this.removeTripListeners()
  },
  methods: {
    triggerChangeDetection(time = 2) {
      this.changeDetectionRef = setTimeout(async () => {
        this.activeTrips.forEach((trip) => {
          const update = !!trip.statusClass // force update
        })
        await this.$nextTick()
      }, time * 60 * 1000)
    },
    getVehicleCategoryImage(trip) {
      return trip.driver?.vehicle?.vehicle_type?.image || null
    },
    clearFilters() {
      (this.filter.city = {
        id: null,
        name: 'All Cities',
        city_id: null
      }),
        (this.filter.tripState = null)
    },
    selectFilter(prop, value) {
      this.filter[prop] = value
    },
    highlightTrip(tripId) {
      const trip = this.activeTrips.find((o) => o.id === tripId)
      if (trip) {
        this.selectedTrip = trip
        const refKey = `activeTrip_${tripId}`
        setTimeout(() => {
          this.$refs[refKey][0].scrollIntoView({ behavior: 'smooth', block: 'center' })
        }, 300)
      }
    },
    async getCities() {
      if (this.cityFilters.length) {
        return
      }
      try {
        const cityData = await RouteResource.getCities()
        this.cityFilters = [
          { id: null, name: 'All Cities', city_id: null },
          ...cityData.data
        ]
      } catch (e) {

      }
    },
    openActiveTripsPanel(searchContext) {
      if (searchContext) {
        if (this.searchQuery) {
          this.tripsPanelOpen = true
        }
      } else {
        this.tripsPanelOpen = true
      }
    },
    closeActiveTripsPanel() {
      this.tripsPanelOpen = false
    },
    async loadActiveTrips(page = 1) {
      let url = `v1/trips/active?limit=${this.pageSize}&page=${page}&metadata=true`
      if (this.filter.city?.city_id) {
        url += `&city_id=${this.filter.city.city_id}`
      }
      const res = await this.axios.get(url)
      return res.data
    },
    paintTripMarkers() {
      this.initWebSocket(this.activeTrips)
    },
    addAdditionalAttribute(obj) {
      const trip = Object.assign({}, obj)
      trip.lastUpdated = null
      trip.initialLoadTime = Date.now()
      Object.defineProperty(trip, 'isOffline', {
        get: function () {
          // is offline when no location update is retrieved after 2 minutes
          return this.lastUpdated === null && Date.now() - this.initialLoadTime > 15000
        }
      })
      Object.defineProperty(trip, 'isOnline', {
        get: function () {
          // Online when last update was in the last 60 seconds
          return this.lastUpdated ? Date.now() - this.lastUpdated <= 60000 : false
        }
      })
      Object.defineProperty(trip, 'isPending', {
        get: function () {
          return !this.isOffline && !this.isOnline && this.lastUpdated === null
        }
      })
      Object.defineProperty(trip, 'statusIcon', {
        get: function () {
          return this.isOffline
            ? require('@/assets/img/icons/mini_alert.svg')
            : require('@/assets/img/icons/navigation_arrow.svg')
        }
      })
      Object.defineProperty(trip, 'lastUpdateState', {
        get: function () {
          return this.isPending
            ? 'Pending'
            : this.isOffline
              ? 'Offline'
              : this.isOnline
                ? 'Live location'
                : `Updated ${timeSince(this.lastUpdated)} ago`
        }
      })
      Object.defineProperty(trip, 'statusClass', {
        get: function () {
          return this.isPending
            ? 'state--pending'
            : this.isOffline
              ? 'state--offline'
              : this.isOnline
                ? 'state--online'
                : 'state--updated'
        }
      })
      return trip
    },
    updateMarkerVisibility() {
      const activeMarkers = this.searchResults
      const activeMarkerMap = this.searchResults.reduce((result, value) => {
        result[value.id] = true
        return result
      }, {})
      const inActiveMarkers = this.activeTrips.filter(
        (trip) => !activeMarkerMap[trip.id]
      )
      inActiveMarkers.forEach((tripRef) => {
        this.setMarkerVisibility(tripRef.id, false)
      })
      activeMarkers.forEach((tripRef) => {
        this.setMarkerVisibility(tripRef.id, true)
      })
    },
    setMarkerVisibility(tripRef, visible) {
      const markerReference = this.activeTripsMarkers[tripRef]
      if (markerReference) {
        markerReference.marker.setVisible(visible)
        markerReference.tMarker.setVisible(visible) // title marker
        if (markerReference.infoWindow()) {
          markerReference.infoWindow().close()
        }
      }
    },
    fetchActiveTrips(initialLoad = true) {
      this.isLoading = true
      this.loadActiveTrips()
        .then((data) => {
          this.activeTrips = (data.data || []).map(this.addAdditionalAttribute)
          this.totalTrips = data.metadata?.total || 0
          this.isLoading = false
          if (this.activeTrips.length) {
            // Triggers a click on the element to force change detection for the pending state in
            // a case where the UI gets no event from the telemetry service the
            this.triggerChangeDetection(2)
          }
        })
        .finally(() => {
          this.isLoading = false
          this.loadGoogleMapsLib(initialLoad)
        })
    },
    async loadGoogleMapsLib(initialLoad = true) {
      const vm = this
      this.activeTripsMarkers = {}
      gMapLoader
        .load()
        .then(() => {
          vm.mapInstance = new window.google.maps.Map(
            document.getElementById('google-map-area'),
            {
              center: this.filter.city?.point
                ? {
                  lat: this.filter.city?.point?.x || 6.5244,
                  lng: this.filter.city?.point?.y || 3.3792
                }
                : { lat: 6.5244, lng: 3.3792 },
              zoom: 12,
              streetViewControl: false,
              mapId: process.env.VUE_APP_MAP_ID,
              fullscreenControl: false,
              disableDefaultUI: true,
              zoomControl: true
            }
          )
        })
        .finally(() => {
          if (initialLoad) {
            vm.paintTripMarkers()
          } else {
            this.activeTrips.forEach((trip) => {
              this.initializeTripListener(trip)
            })
          }
        })
    },
    updateTripInformation(tripInfo, locationInfo) {
      const prevTrackingInfo = this.uniqueTripTracking[tripInfo.id]
      const { position_latitude, position_longitude } = locationInfo
      const trip = this.activeTrips.find((o) => o.id == tripInfo.id)
      if (trip) {
        trip.lastUpdated = Date.now()
      }
      if (!this.activeTripsMarkers[tripInfo.id]) {
        this.setTripCurrentMarker(tripInfo, position_latitude, position_longitude)
      } else {
        const newLatLng = new window.google.maps.LatLng(
          position_latitude,
          position_longitude
        )
        this.activeTripsMarkers[tripInfo.id].marker.setPosition(newLatLng)
        this.activeTripsMarkers[tripInfo.id].tMarker.setPosition(newLatLng)
      }

      if (prevTrackingInfo && this.coordinateChanged(prevTrackingInfo, locationInfo)) {
        const icon = this.activeTripsMarkers[tripInfo.id].marker.getIcon()
        const rotation = this.computeHeading(
          prevTrackingInfo.position_latitude,
          prevTrackingInfo.position_longitude,
          position_latitude,
          position_longitude
        )
        icon.url = busMarker(rotation)
        this.activeTripsMarkers[tripInfo.id].marker.setIcon(icon)
      }
      this.uniqueTripTracking[tripInfo.id] = locationInfo
    },
    coordinateChanged(previousCoordinateData, currentCoordinateData) {
      return (
        previousCoordinateData.position_latitude !==
        currentCoordinateData.position_latitude ||
        previousCoordinateData.position_longitude !==
        currentCoordinateData.position_longitude
      )
    },
    setTripCurrentMarker(tripInfo, newLatitude = null, newLongitude = null) {
      const startingPoint = tripInfo.route.pickup_coordinate
        .split(',')
        .map((i) => parseFloat(i.trim()))
      const [lat, lng] = startingPoint
      if ((lat && lng) || (newLatitude && newLongitude)) {
        this.activeTripsMarkers[tripInfo.id] = this.addBusMarker(
          lat || 0,
          lng || 0,
          newLatitude || lat,
          newLongitude || lng,
          `${tripInfo.route.route_code}`,
          `B/S: ${tripInfo.route.pickup}`,
          `Destination: ${tripInfo.route.destination}`,
          tripInfo.id
        )
      }
    },

    async initWebSocket(activeTrips) {
      await this.$socketController.joinRoom('shuttlers:admin')
      activeTrips.forEach((trip) => {
        this.initializeTripListener(trip)
      })
    },
    removeTripListeners() {
      Object.keys(this.uniqueTripData).forEach((key) => {
        this.$socketController.off(`trips:${key}`)
      })
    },
    initializeTripListener(trip) {
      if (!this.uniqueTripData[trip.id]) {
        this.uniqueTripData[trip.id] = trip
        // this.setTripCurrentMarker(trip);
        this.$socketController.on(`trips:${trip.id}`, (data) => {
          if (data) {
            this.updateTripInformation(trip, data)
          }
        })
      }
    },
    closePassengersModal() {
      this.isPassengersModalShown = false
      this.selectedUserRoutes = []
    },
    showPassengersModal(tripId) {
      this.selectedUserRoutes = this.activeTrips.filter((trip) => trip.id == tripId)
      this.selectedMarkerTripData = this.selectedUserRoutes.length
        ? this.selectedUserRoutes[0]
        : null
      this.isPassengersModalShown = true
    },

    addBusMarker(prevLat, prevLng, lat, lng, title, startingPoint, description, tripId) {
      const latLng = new window.google.maps.LatLng(lat, lng)
      const bearing = this.computeHeading(prevLat, prevLng, lat, lng)
      const marker = new window.google.maps.Marker({
        position: latLng,
        map: this.mapInstance,
        collisionBehavior: 'REQUIRED_AND_HIDES_OPTIONAL',
        icon: {
          // labelOrigin: {x: 8, y: 35}, //below marker
          url: busMarker(bearing),
          anchor: new window.google.maps.Point(33, 45),
          size: new window.google.maps.Size(66, 98),
          rotation: bearing
        }
      })

      // Using this to handle events due to asset dimension used in rendered marker
      const tMarker = new window.google.maps.Marker({
        position: latLng,
        map: this.mapInstance,
        optimized: false,
        visible: true,
        label: {
          text: title,
          className: 'marker-label'
        },
        collisionBehavior: 'REQUIRED_AND_HIDES_OPTIONAL',
        icon: {
          labelOrigin: { x: 14, y: -14 }, // above marker
          url: 'https://maps.gstatic.com/mapfiles/transparent.png',
          size: new window.google.maps.Size(20, 46),
          anchor: new window.google.maps.Point(10, 23)
        }
      })

      let infoWindow
      let infoWindowRef = null
      const infoWindowOffSet = new window.google.maps.Size(0, 0)
      tMarker.addListener('click', () => {
        infoWindowRef = this.openInfoWindow(
          tMarker,
          title,
          startingPoint,
          description,
          infoWindowOffSet
        )
        this.panAndZoom(marker.getPosition())
        this.highlightTrip(tripId)
      })
      tMarker.addListener('mouseover', () => {
        infoWindow = infoWindowRef = this.openInfoWindow(
          tMarker,
          title,
          startingPoint,
          description,
          infoWindowOffSet
        )
      })
      tMarker.addListener('mouseout', () => {
        if (infoWindow) {
          infoWindow.close()
        }
      })

      return { marker, tMarker, infoWindow: () => infoWindowRef }
    },
    rotateMarkerParent(label, degree) {
      const el = document.body.querySelector(`div[aria-label$="${label}"]`)
      if (el) {
        el.style.transform = `rotate(${degree}deg)`
        el.style.transformOrigin = 'center'
      }
    },
    openInfoWindow(busMarker, title, start, destination, pixelOffset = null) {
      const img = require('@/assets/img/mini_marker_dark.svg')
      const contentString =
        '<div class="info-window">' +
        `<div class="info-window__source"><p class="d-flex mb-3"><span class="source-badge"></span> <span class="text">${start}</span></p></div>` +
        `<div class="info-window__destination"><p class="d-flex align-items-start mb-0"><img src="${img}" class="icon" /> <span class="text"> ${destination}</span></p></div>` +
        '</div>'
      const extras = pixelOffset ? { pixelOffset } : {}
      const infoWindow = new window.google.maps.InfoWindow({
        content: contentString,
        ...extras
      })
      infoWindow.open(this.mapInstance, busMarker)
      return infoWindow
    },
    computeHeading(lat1, lng1, lat2, lng2) {
      const point1 = new window.google.maps.LatLng(lat1, lng1)
      const point2 = new window.google.maps.LatLng(lat2, lng2)
      return window.google.maps.geometry.spherical.computeHeading(point1, point2)
    },
    selectActiveTrip(trip) {
      this.selectedTrip = trip
      this.panToSelectedActiveTrip(trip)
    },
    panToSelectedActiveTrip(trip) {
      const tripToPanTo = this.activeTripsMarkers[trip.id]?.marker
      if (tripToPanTo) {
        this.panAndZoom(tripToPanTo.getPosition())
      }
    },
    panAndZoom(position) {
      this.mapInstance.panTo(position)
      this.mapInstance.setZoom(15)
    },
    loadMore(page) {
      this.loadingMoreTrips = true
      this.loadActiveTrips(page)
        .then((data) => {
          const newTrips = (data?.data || []).map(this.addAdditionalAttribute)
          this.activeTrips.push(...newTrips)
          newTrips.forEach((trip) => {
            this.initializeTripListener(trip)
          })
          this.totalTrips = data?.metadata?.total || 0
          this.isLoading = false
        })
        .finally(() => {
          this.loadingMoreTrips = false
        })
    },
    highlightTripOnSidePanel(tripId) {
      this.openActiveTripsPanel()
      this.markerClickListener.call(this, tripId)
    },
    loadMoreTrips() {
      this.currentPage++
      setTimeout(() => {
        this.loadMore(this.currentPage)
      }, 100)
    }
  },
  beforeDestroy() {
    if (this.changeDetectionRef) {
      clearTimeout(this.changeDetectionRef)
    }
  }
}
</script>

<style lang="scss" scoped>
@use "src/assets/scss/partials/sh-colors" as colors;

.small {
  font-size: 0.7rem;
  max-width: 7rem;
}

.page-container {
  background: #f5f5f5;
  padding: 0rem;

  .page-header-wrapper {
    margin-bottom: 0rem;
  }

  .content-wrapper {
    //border: 3px solid #fff;
    position: relative;
    height: calc(100vh - 4.5rem);
    padding: 0 2.25rem;

    .content-area {
      border-radius: 0.375rem;
      background-color: #ffffff;
      position: relative;
      padding: 0rem;
      width: 100%;
      display: flex;
      flex-direction: column;

      .content-header {
        display: flex;
        width: 100%;
        align-items: center;
        flex-wrap: wrap;

        .input-group {
          background: #ffffff;
          border: 0.5px solid #c0c0c0;
          border-radius: 8px;
          width: 550px;
          max-width: 100%;
          align-items: center;
        }
      }

      .content-body {
        overflow: hidden;
        display: flex;
        flex-grow: 1;
        overflow-x: scroll;
        position: relative;
        //height: calc(100% - 3.925rem);

        .list-group-item {
          color: colors.$sh-neutral-800;
          font-size: 0.75rem;
          padding: 1.5rem;

          //box-shadow: 0px 10px 24px rgb(33 35 37 / 8%);
          &:not(:last-of-type) {
            border-bottom: 0.5px solid #dadada;
          }

          p {
            margin: 0;
          }

          .category-icon {
            width: 4.625rem;
            height: 4.625rem;
            border-radius: 12px;
            object-fit: contain;
            border: 1px solid #ecedef;
            background: #fff;
          }

          .category-placeholder {
            width: 4.625rem;
            height: 4.625rem;
            border: 1px solid #ecedef;
            border-radius: 12px;
            display: flex;
            align-items: center;
            justify-content: center;

            .img {
              height: 2rem;
              width: 2rem;
            }
          }

          .route-info {
            border-bottom: 1px solid #ecedef;
          }

          &:hover,
          &--active {
            border-radius: 0.5rem;
            background: #ddd; // colors.$sh-neutral-600;
            // color: #ffffff;
            transition: all 0.3s;

            .category-icon,
            .category-placeholder {
              border: 1px solid #a9acab;
            }

            .route-info {
              border-bottom: 1px solid #a9acab;
            }

            .state--pending,
            .state--offline {
              .state-label {
                //color: #ffffff;
              }
            }
          }

          .label-text {
            margin-right: 0.25rem;
            color: colors.$sh-neutral-400;
          }
        }

        .pointer {
          cursor: pointer;
        }

        .state {
          display: flex;
          //align-items: center;
          font-weight: 500;
          font-size: 0.75rem;
          line-height: 1.25rem;

          svg {
            height: 1rem;
          }

          &--offline {
            color: colors.$sh-red;
          }

          &--pending {
            color: colors.$sh-gray-3;
          }

          &--online {
            color: colors.$sh-green-500;
          }
        }

        .map-area {
          border-radius: 0.5rem;
        }
      }
    }
  }

  .filter-button {
    border: 1px solid #d0d5dd;
    border-radius: 0.5rem;
    padding: 0.5rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .empty-state-img {
    max-width: 16.25rem;
    margin: 0 auto;
  }
}
</style>
<style lang="scss" scoped>
.marker-label {
  font-size: 10px !important;
  background: #fff;
  padding: 3px 10px;
  border-radius: 5px;
  font-weight: 500;
}

.gm-style .gm-style-iw-c {
  border-radius: 1rem;
  box-shadow: none;

  & .gm-ui-hover-effect {
    top: 0 !important;
    right: 0 !important;
  }
}

.info-window {
  padding: 0 0.25rem;
  background: #ffffff;
  font-weight: 400;
  font-size: 12px;
  max-width: 20rem;

  .text {
    max-width: 10rem;
  }

  &__source {
    color: #313533;
    position: relative;

    &:before {
      border-left: 1px dashed #a3a3a3;
      position: absolute;
      top: 12px;
      left: 4px;
      height: 80%;
      content: "";
    }

    .source-badge {
      background: #20e682;
      height: 10px;
      width: 10px;
      border-radius: 50%;
      display: inline-block;
      margin-right: 1rem;
    }
  }

  &__destination {
    color: #acafae;

    .icon {
      margin-right: 1rem;
      width: 10px;
    }
  }
}

.max-width-400 {}

.one-liner {}

.badge {
  padding: 0.375rem;
}

.route-code {
  font-size: 16px;
  text-align: end;
  padding-top: 10px;
}

.map-spread {
  display: block;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}
</style>
